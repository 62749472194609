import React, { useState, useEffect } from "react";

const ImageSlider = () => {
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Mocking the images fetching from a folder
        const imagesContext = require.context(
          "../../assets/images/landing",
          false,
          /\.(png|jpe?g|svg|webp)$/
        );
        const images = imagesContext.keys().map(imagesContext);
        setImages(images);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };
    fetchImages();
  }, []);

  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      {/* Carousel Indicators */}
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : ""}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      {/* Carousel Items */}
      <div className="carousel-inner">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img
                src={image}
                className="d-block w-100 img-fluid rounded"
                alt={`slide ${index + 1}`}
                loading="lazy"
              />
            </div>
          ))
        ) : (
          <div className="carousel-item active">
            <p className="text-center">No images available</p>
          </div>
        )}
      </div>
      {/* Carousel Controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default ImageSlider;
