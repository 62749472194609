import GalleryComp from "./components/GalleryComp";
import { k1Images, k2Images, k3Images } from "../utils/importImage";
import logo from "../assets/images/branding/k-logo-nt.png";

function Gallery() {
  return (
    <section className="container text-center">
      <div className="heading">
        <h5>Explore Kwantu</h5>
        <h1>Gallery</h1>
      </div>
      <h2>Kwantu 1</h2>
      <GalleryComp images={k1Images} />
      <h2>Kwantu 2 </h2>
      <GalleryComp images={k2Images} />
      <h2>Kwantu 3</h2>
      <GalleryComp images={k3Images} />

      <div className="card align-items-center">
        <i className="bi bi-patch-check"></i>
        <div className="card-body">
          <p className="card-text">For Your Enjoyable Stay</p>
          <h3 className="card-title">Kwantu Guesthouses</h3>
        </div>
      </div>
      <div className="d-flex py-2">
        <div className="col">
          <div className="card align-items-center align-items-start flex-column mb-3">
            <i className="bi bi-calendar-check"></i>
            <div className="card-body text-center">
              <p className="card-text">Book Your Stay</p>
              <button type="button" className="btn btn-dark nav-book">
                <a href="https://book.nightsbridge.com/14037" target="_blank">
                  <span>Book Now</span>
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="col d-flex justify-content-center align-items-center">
          <img src={logo} className="img-fluid" alt="" />
        </div>
        <div className="col">
          <div className="card align-items-center align-items-start flex-column mb-3">
            <i className="bi bi-telephone"></i>
            <div className="card-body text-center">
              <p className="card-text">Contact Kwantu</p>
              <button
                type="button"
                className="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#contactModal"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
