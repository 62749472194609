const facilitiesData = {
  catering: [
    {
      name: "",
      description:
        "Our chefs craft delicious menus with the freshest ingredients, catering to all dietary preferences. Dedicated to providing outstanding food and service, making your event truly special. ",
      image1: require("../images/facilities/catering/1.jpg"),
      image2: require("../images/facilities/catering/2.jpg"),
      image3: require("../images/facilities/catering/3.jpg"),
      image4: require("../images/facilities/catering/4.jpg"),
    },
  ],
  functions: [
    {
      name: "",
      description:
        "Perfect for weddings, birthdays, and corporate events, tailored to suit your needs. Our passionate team ensures every detail is flawless, from decor to entertainment. Host unforgettable events in a beautiful and accommodating setting.",
      image1: require("../images/facilities/functions/1.jpg"),
      image2: require("../images/facilities/functions/2.jpg"),
      image3: require("../images/facilities/functions/3.jpg"),
      image4: require("../images/facilities/functions/4.jpg"),
    },
  ],
  conference: [
    {
      name: "",
      description:
        "Equipped with high-speed internet, audio-visual equipment, and comfortable seating for productive meetings. Flexible spaces to accommodate both small and large business events. Our experienced staff assists with planning and execution to ensure seamless events.",

      image1: require("../images/facilities/conference/co1.jpg"),
      image2: require("../images/facilities/conference/co2.jpg"),
      image3: require("../images/facilities/conference/co3.jpg"),
      image4: require("../images/facilities/conference/co4.jpg"),
    },
  ],

  shuttle: [
    {
      name: "",
      description:
        "At Kwantu Guesthouses, we believe your journey should be as enjoyable as your stay. That’s why we offer a convenient and reliable shuttle service to make your travel hassle-free. Whether you're arriving at the airport, exploring the vibrant city, or heading out to Cape Town’s stunning attractions, our dedicated shuttle service ensures you reach your destination comfortably and on time.",

      image1: require("../images/facilities/shuttle/sh1.jpg"),
      image2: require("../images/facilities/shuttle/sh2.jpg"),
      image3: require("../images/facilities/shuttle/sh3.jpg"),
      image4: require("../images/facilities/shuttle/sh4.jpg"),
    },
  ],
};

export default facilitiesData;
