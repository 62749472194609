import React from "react";

// List of attractions with corresponding images
const attractions = [
  { title: "Kirstenbosch National Botanical Garden", image: "kirstenbosch.jpg" },
  { title: "Iziko Bo-Kaap Museum", image: "bo_kaap_museum.jpg" },
  { title: "V&A Waterfront", image: "v_and_a_waterfront.jpg" },
  { title: "Aquila Game Reserve", image: "Aquila Game Reserve.jpg" },
  { title: "Two Oceans Aquarium", image: "two_oceans_aquarium.jpg" },
  { title: "Zeitz Museum of Contemporary Art Africa", image: "zeitz_museum.webp" },
  { title: "Cape of Good Hope", image: "cape_of_good_hope.webp" },
  { title: "Atlantic Spa Treatment", image: "Atlantic Spa Treatment.jpg" },
  { title: "Lions Head", image: "Lions Head.jpg" },
  { title: "Canal Walk Shopping Centre", image: "canal_walk.jpg" },
  { title: "Table Mountain National Park", image: "table_mountain.jpg" },
  { title: "District Six Museum", image: "district_six_museum.jpg" },
  { title: "Atlantis Dunes", image: "Atlantis Dunes.jpg" },
  { title: "Oranjezicht City Farm Market", image: "oranjezicht_farm.jpg" },
  { title: "Sunset Cruises", image: "Sunset Cruises.jpg" },
  { title: "Blouberg Beach", image: "Blouberg Beach.jpg" },
  { title: "Muizenberg Beach", image: "Muizenberg Beach.jpg" },
  { title: "DHL Stadium", image: "dhl_stadium.jpg" },
  { title: "Robben Island", image: "robben_island.jpg" },
  { title: "Iziko South African Museum", image: "sa_museum.jpg" },
  { title: "Franschhoek Wine Estates", image: "Franschhoek Wine Estates.jpg" },
];

// Function to import images dynamically
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

// Import images from assets folder
const images = importAll(
  require.context("../../assets/images/attractions", false, /\.(png|jpe?g|svg|webp)$/)
);

const ImageGrid = () => {
  return (
    <div className="container imageGrid text-center">
      <div className="row">
        {attractions.map((attraction, index) => (
          <div className="col-12 col-md-4 cont" key={index}>
            <img
              src={images[attraction.image]}
              alt={attraction.title}
              className="img-fluid m-2 rounded shadow"
              loading="lazy"
            />
            <h5 className="mt-2">{attraction.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
