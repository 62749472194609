// src/utils/importImages.js

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export const k1Images = importAll(
  require.context(
    "../assets/images/guesthouses/k1",
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);
export const k2Images = importAll(
  require.context(
    "../assets/images/guesthouses/k2",
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);
export const k3Images = importAll(
  require.context(
    "../assets/images/guesthouses/k3",
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);
