import React from "react";

import "../../assets/styles/pages/maps.css";

function Maps() {
  return (
    <section className="map top" id="map">
      <div className="container">
        <div className="heading">
          <h5>Find us</h5>
          <h2>Location</h2>
          
          <h6 className="bold">
            All 3 guesthouses are a stone's throw from one another, no more
            than 200m apart.
          </h6>
        
        </div>

        <ul className="nav nav-pills nav-fill" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link border active"
              id="k1-tab"
              data-bs-toggle="tab"
              data-bs-target="#k1-tab-pane"
              type="button"
              role="tab"
              aria-controls="k1-tab-pane"
              aria-selected="true"
            >
              Kwantu 1
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link border"
              id="k2-tab"
              data-bs-toggle="tab"
              data-bs-target="#k2-tab-pane"
              type="button"
              role="tab"
              aria-controls="k2-tab-pane"
              aria-selected="false"
            >
              Kwantu 2
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link border"
              id="k3-tab"
              data-bs-toggle="tab"
              data-bs-target="#k3-tab-pane"
              type="button"
              role="tab"
              aria-controls="k3-tab-pane"
              aria-selected="false"
            >
              Kwantu 3
            </button>
          </li>
        </ul>
        <div className="tab-content text-center" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="k1-tab-pane"
            role="tabpanel"
            aria-labelledby="k1-tab"
            tabIndex="0"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.868458448951!2d18.5086513!3d-33.8518507!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5ec5bb75ee09%3A0xb66d12759c85e439!2sKwantu%20Guest%20House!5e0!3m2!1sen!2sza!4v1678715421391!5m2!1sen!2sza"
              width="600"
              height="450"
              title="kwantu-1"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div
            className="tab-pane fade"
            id="k2-tab-pane"
            role="tabpanel"
            aria-labelledby="k2-tab"
            tabIndex="0"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.6342693205!2d18.5097999!3d-33.85336!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5ec5e3739815%3A0x60062fc1317542f6!2sKwantu%20Guest%20House%202!5e0!3m2!1sen!2sza!4v1679556885345!5m2!1sen!2sza"
              width="600"
              height="450"
              title="kwantu-2"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            className="tab-pane fade"
            id="k3-tab-pane"
            role="tabpanel"
            aria-labelledby="k3-tab"
            tabIndex="0"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13254.171309556023!2d18.5103509!3d-33.8498988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5fb396e406e5%3A0x71deff4f477ee28d!2sKwaNtu%20Guest%20House%203!5e0!3m2!1sen!2sza!4v1710777201872!5m2!1sen!2sza"
              width="600"
              height="450"
              title="kwantu-3"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        

        <div className="d-flex justify-content-evenly flex-row">
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
      </div>
    </section>
  );
}

export default Maps;
