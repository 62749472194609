import React from "react";
import logo from "../../assets/images/branding/k-logo-t.png";

function Header() {
  return (
    <div className="head py-2">
      <div className="d-flex justify-content-between align-items-center px-3 flex-wrap">
        {/* Social Media Links */}
        <div className="d-flex order-2 order-md-1 mt-2 mt-md-0">
          <a
            href="https://www.facebook.com/profile.php?id=61550234398860"
            target="_blank"
            rel="noreferrer"
            className="px-2"
          >
            <i className="bi bi-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/kwantu_guesthouses/?fbclid=IwAR2mbjsv-KYs1lO-ADbM2SehE2Hyg0UQNnyKo_AG9C0kKE-9rKaPMAGj3M8"
            target="_blank"
            rel="noreferrer"
            className="px-2"
          >
            <i className="bi bi-instagram"></i>
          </a>
        </div>

        {/* Logo */}
        <div className="order-1 order-md-2 mb-2 mb-md-0">
          <img
            src={logo}
            className="img-fluid mh-50 d-block mx-auto"
            alt="Logo"
          />
        </div>

        {/* Contact Information */}
        <div className="order-3 text-center text-md-end">
          <h6 className="badge text-bg-light d-flex align-items-center justify-content-center mt-2 mt-md-0">
            <i className="bi bi-telephone-fill me-1"></i>
            <span>+27-21-552-4390</span>
          </h6>
          <a
                href="https://wa.me/27835711688"
                target="_blank"
                rel="noreferrer"
                className="d-flex align-items-center text-decoration-none"
              >
          <h6 className="badge text-bg-light d-flex align-items-center justify-content-center mt-2 mt-md-0">
            <i className="bi bi-whatsapp me-1"></i>
            <span>+27-83-571-1688</span>
          </h6>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
