import React from "react";


import ImageSlider from "../components/ImageSlider";

function Landing() {
  return (
    <section className="landing p-0" id="landing">
      <div className="img-fluid d-flex justify-content-center container">
        <ImageSlider></ImageSlider>
      </div>
    </section>
  );
}

export default Landing;
