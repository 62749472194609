import React from "react";
import "../../assets/styles/pages/guesthouses.css";
import k1 from "../../assets/images/guesthouses/k1/8b.jpg";
import k2 from "../../assets/images/guesthouses/k2/1.jpg";
import k3 from "../../assets/images/guesthouses/Kwantu 3.jpg";
import sc from "../../assets/images/guesthouses/sc.jpg"
import cptt from "../../assets/images/partners/Cape town tourism.svg";
import booking from "../../assets/images/partners/booking.png";
import expedia from "../../assets/images/partners/expedia-logo.svg";
import fedhasa from "../../assets/images/partners/fedhasa-logo.svg";
import nightb from "../../assets/images/partners/nightsbridge-logo.png";
import tgclogo from "../../assets/images/about/TGC-logo.jpg";
import { Link as RouterLink } from "react-router-dom";

function Guesthouses() {
  return (
    <section className="guesthouse container my-5" id="guesthouses">
      <div className="heading text-center mb-4">
        <h2>Three Guesthouses</h2>
      </div>

      <div className="text-center">
        {/* Guesthouse 1 */}
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <RouterLink to="/Gallery">
              <img src={k1} className="img-fluid rounded shadow" alt="Kwantu 1" />
            </RouterLink>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <RouterLink to="/Gallery">
              <h5>Kwantu 1</h5>
            </RouterLink>
            <p>
              Welcome to Kwantu Guesthouse 1, your serene retreat in the heart
              of Cape Town. Nestled in the quiet, leafy suburb of Milnerton Ridge,
              our guesthouse offers a perfect blend of comfort and convenience.
              Enjoy the modern, stylish décor and unwind by the pool. Whether
              you're here for business or leisure, Kwantu Guesthouse 1 provides
              a peaceful escape with easy access to the city's top attractions.
            </p>
            <RouterLink to="/Gallery">
              <button type="button" className="btn btn-dark nav-book">
                Gallery
              </button>
            </RouterLink>
          </div>
        </div>

        {/* Guesthouse 2 */}
        <div className="row mb-4">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <RouterLink to="/Gallery">
              <h5>Kwantu 2</h5>
            </RouterLink>
            <p>
              Discover the charm of Kwantu Guesthouse 2, a haven of tranquility
              and elegance. Situated close to Cape Town's vibrant city center,
              this guesthouse combines contemporary design with a warm,
              welcoming atmosphere. Indulge in our luxurious rooms and
              experience the best of Cape Town's culture and nightlife just a
              short drive away. At Kwantu Guesthouse 2, your comfort is our
              priority.
            </p>
            <RouterLink to="/Gallery">
              <button type="button" className="btn btn-dark nav-book">
                Gallery
              </button>
            </RouterLink>
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <RouterLink to="/Gallery">
              <img src={k2} className="img-fluid rounded shadow" alt="Kwantu 2" />
            </RouterLink>
          </div>
        </div>

        {/* Guesthouse 3 */}
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <RouterLink to="/Gallery">
              <img src={k3} className="img-fluid rounded shadow" alt="Kwantu 3" />
            </RouterLink>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <RouterLink to="/Gallery">
              <h5>Kwantu 3</h5>
            </RouterLink>
            <p>
              Experience unparalleled comfort at Kwantu Guesthouse 3, where
              sophistication meets relaxation. Located near the picturesque
              Table View Nature Reserve, this guesthouse offers breathtaking
              views and top-notch amenities. Our attentive staff is dedicated to
              making your stay unforgettable. Whether you're exploring Cape
              Town's natural beauty or attending a business meeting, Kwantu
              Guesthouse 3 is your ideal home away from home.
            </p>
            <RouterLink to="/Gallery">
              <button type="button" className="btn btn-dark nav-book">
                Gallery
              </button>
            </RouterLink>
          </div>

        </div>
        {/* Guesthouse 3 */}
        <div className="row mb-4">

          <div className="col-md-6 d-flex flex-column justify-content-center">
            <RouterLink to="/Gallery">
              <h5>Self Catering Units</h5>
            </RouterLink>
            <p>
            Welcome to your home away from home in Cape Town! Our three-bedroom self-catering unit is the perfect choice for families, groups, or guests planning longer stays. Nestled in a serene neighborhood, this spacious unit offers all the comforts you need to relax, unwind, and enjoy a truly memorable stay.
            </p>
            <RouterLink to="/Gallery">
              <button type="button" className="btn btn-dark nav-book">
                Gallery
              </button>
            </RouterLink>
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <RouterLink to="/Gallery">
              <img src={sc} className="img-fluid rounded shadow" alt="Kwantu 3" />
            </RouterLink>
          </div>
        </div>
      </div>

      <div className="text-center mt-5">
        <h6 className="mb-4">In Proud Partnership</h6>
        <div className="d-flex flex-wrap justify-content-around align-items-center">
          <img src={cptt} className="img-fluid mb-2" alt="Cape Town Tourism" style={{ maxWidth: "150px" }} />
          <img src={fedhasa} className="img-fluid mb-2" alt="Fedhasa" style={{ maxWidth: "150px" }} />
          <img src={booking} className="img-fluid mb-2" alt="Booking" style={{ maxWidth: "150px" }} />
          <img src={nightb} className="img-fluid mb-2" alt="Nightsbridge" style={{ maxWidth: "150px" }} />
          <img src={expedia} className="img-fluid mb-2" alt="Expedia" style={{ maxWidth: "150px" }} />
          <img src={tgclogo} className="img-fluid mb-2" alt="TGC Logo" style={{ maxWidth: "150px" }} />
        </div>
      </div>
    </section>
  );
}

export default Guesthouses;
