import React from "react";
import "../../assets/styles/pages/amenities.css";
import logo from "../../assets/images/branding/k-logo-nt.png";

function Amenities() {
  return (
    <section className="amenities py-5" id="amenities">
      <div className="container">
        <div className="text-center mb-5 heading">
          <h5>What Kwantu Offers</h5>
          <h2>Guest Amenities</h2>
        </div>

        <div className="row text-center gy-4">
          {/* Amenity Cards */}
          {[
            { icon: "bi-wifi", title: "High Speed Wifi", text: "Free" },
            { icon: "bi-ev-front", title: "Airport Shuttle", text: "Efficient" },
            { icon: "bi-fire", title: "Braai (Barbecue)", text: "Sizzling" },
            { icon: "bi-water", title: "Swimming Pool", text: "Crystal Clear" },
            { icon: "bi-droplet-half", title: "Laundry Facilities", text: "Neat & Tidy" },
            { icon: "bi-tv", title: "Satellite TV", text: "Modern" },
            { icon: "bi-clipboard2-check", title: "Room Service", text: "Professional" },
            { icon: "bi-wind", title: "Air Conditioners", text: "Refreshing" },
            { icon: "bi-lightning-charge", title: "Inverters", text: "Convenient" },
            { icon: "bi-bookmark-star-fill", title: "Local Attractions", text: "Engaging" },
            { icon: "bi-brush", title: "African Artifacts", text: "Beautiful" },
            { icon: "bi-plus-circle-dotted", title: "So Much More", text: "And" },
          ].map((amenity, index) => (
            <div key={index} className="col-md-4">
              <div className="card mb-3">
                <i className={`bi ${amenity.icon} card-img-top`}></i>
                <div className="card-body text-center">
                  <p className="card-text">{amenity.text}</p>
                  <h5 className="card-title">{amenity.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Special Cards */}
        <div className="row text-center mb-5">
          <div className="col-md-4 mb-4">
            <div className="card">
              <i className="bi bi-patch-check"></i>
              <div className="card-body">
                <p className="card-text">For Your Enjoyable Stay</p>
                <h5 className="card-title">Kwantu Guesthouses</h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src={logo} className="img-fluid" alt="Kwantu Logo" />
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <i className="bi bi-telephone"></i>
              <div className="card-body">
                <p className="card-text">Contact Kwantu</p>
                <button
                  type="button"
                  className="btn btn-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#contactModal"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Info */}
        <div className="text-center">
          <p>
            Look no further than Kwantu Cape Town for your hosting or
            accommodation needs. Our rates are affordable, and our
            guesthouses are beautiful. Contact us today for a quotation or
            any inquiries. Whether it's for corporate events, family visits,
            school groups, or tourists, we look forward to hearing from you.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Amenities;
