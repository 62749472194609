import React from "react";

import Landing from "./sections/Landing";
import About from "./sections/About";
import Guesthouses from "./sections/Guesthouses";
import Amenities from "./sections/Amenities";
import Review from "./sections/Review";
import Maps from "./sections/Maps";


function Home() {
  return (
    <>
      <Landing />
      <About />
      <Guesthouses />
      <Amenities />
      <Maps />
      <Review />
    </>
  );
}

export default Home;
