import React from "react";
import "../../assets/styles/pages/about.css";
import tgclogo from "../../assets/images/about/TGC-logo.jpg";
import tgcstar from "../../assets/images/about/TGC-star.jpeg";

function About() {
  return (
    <section className="about" id="about">
      <div className="container">
        {/* Heading */}
        <div className="heading text-center mb-4">
          <h1>Welcome To Kwantu Guesthouses</h1>
        </div>

        {/* Carousel Section */}
        <div className="text-center">
          <div className="row justify-content-center">
            <div
              id="carouselAboutAutoplaying"
              className="carousel slide mt-5"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <i className="bi bi-calendar2-heart"></i>
                  <h6>
                    Established in 2004 and starting with only 5 rooms, Kwantu
                    Guesthouses has grown its offering to 33 stunning suites
                    across 3 spacious properties.
                  </h6>
                </div>
                <div className="carousel-item">
                  <i className="bi bi-building-check"></i>
                  <h6>
                    Kwantu's prestigious portfolio of guesthouses cannot be
                    missed in{" "}
                    <a
                      href="https://goo.gl/maps/ZofNizC5UyjMVQXx5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Milnerton Ridge.
                    </a>
                  </h6>
                </div>
                <div className="carousel-item">
                  <i className="bi bi-calendar-check"></i>
                  <h6>
                    <a href="https://book.nightsbridge.com/14037">Book your stay</a>{" "}
                    at Kwantu Guesthouses and immerse yourself in a place of
                    gathering.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* About Copy Section */}
        <div className="about-copy text-center my-4">
          <p>
            Our three guesthouses, located in close proximity to one another,
            are designed to cater to the diverse needs of our clients, whether
            for day stays or extended visits. Positioned perfectly for both
            local and international guests, our establishments provide easy
            access to the Central Business District for business travelers and
            various attractions for leisure visitors. Enjoy nearby beaches, wine
            farms, Table Mountain, the V&A Waterfront, and more.
          </p>

          {/* Star Images and Logo */}
          <div className="container my-4">
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <img className="img-fluid m-2" src={tgcstar} alt="Star Rating" style={{ maxWidth: "200px" }} />
              <img className="img-fluid m-2" src={tgcstar} alt="Star Rating" style={{ maxWidth: "200px" }} />
              <img className="img-fluid m-2" src={tgcstar} alt="Star Rating" style={{ maxWidth: "200px" }} />
            </div>
            <div className="text-center mt-4">
              <img className="img-fluid" src={tgclogo} alt="Kwantu Guesthouses Logo" style={{ maxWidth: "350px" }} />
            </div>
          </div>
          </div>

          {/* Cards Section */}
          <div className="row p-3 text-center">
            <div className="col-md-6 mb-3">
              <div className="card-body">
                <i className="bi bi-briefcase"></i>
                <h4 className="card-title">Ideal for Business and Leisure</h4>
                <p className="card-text">
                  For those on business trips, our strategic location ensures fast
                  access to the Central Business District. Leisure travelers will
                  appreciate our convenient proximity to popular attractions,
                  including stunning beaches, renowned wine farms, Table Mountain,
                  and the vibrant V&A Waterfront.
                </p>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card-body">
                <i className="bi bi-heart-pulse"></i>
                <h4 className="card-title">Health and Comfort</h4>
                <p className="card-text">
                  Your health needs are prioritized, with medical facilities like
                  Mediclinic Milnerton just a short drive away. For long-stay
                  guests or those with the preference, we offer self-catering
                  units ranging from one to three bedrooms, all fully equipped and
                  ready for you to settle in comfortably.
                </p>
              </div>
            </div>
          </div>

          <div className="row p-3 text-center">
            <div className="col-md-6 mb-3">
              <div className="card-body">
                <i className="bi bi-balloon"></i>
                <h4 className="card-title">Event Hosting</h4>
                <p className="card-text">
                  Kwantu Cape Town is equipped to host various functions, events,
                  workshops, conferences, and training sessions. Our dedicated
                  team goes the extra mile, assisting in creating a tailor-made
                  itinerary for your visit to the Mother City.
                </p>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card-body">
                <i className="bi bi-globe-europe-africa"></i>
                <h4 className="card-title">Local Amenities and Activities</h4>
                <p className="card-text">
                  Kwantu Cape Town is surrounded by a wealth of local amenities
                  and activities to keep you entertained. Discover nearby shopping
                  centers, restaurants, and cafes offering a variety of cuisines.
                  Our guesthouses are also close to cultural landmarks, museums,
                  and entertainment venues, ensuring there's always something
                  exciting to do.
                </p>
              </div>
            </div>
          </div>

          {/* Booking and Contact Card */}
          <div className="card text-center m-5">
            <div className="card-header">Kwantu Guesthouses Cape Town</div>
            <div className="card-body">
              <i className="bi bi-calendar-check"></i>
              <h4 className="card-title">Book At Kwantu Today</h4>
              <p className="card-text">
                At Kwantu Guesthouses, we strive to make your stay as comfortable
                and enjoyable as possible. Our rooms are equipped with: Free
                high-speed Wi-Fi, Air conditioning, Flat-screen TVs with satellite
                channels, Plush, comfortable bedding, En-suite bathrooms with
                complimentary toiletries, Mini-fridges and coffee/tea making
                facilities, Work desks, seating areas, and daily housekeeping
                services. We aim to provide all the comforts and conveniences you
                need to make your stay in Cape Town truly memorable.
              </p>
              <div className="d-flex justify-content-evenly">
                <button
                  type="button"
                  className="btn btn-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#contactModal"
                >
                  Contact
                </button>
                <button type="button" className="btn btn-dark nav-book">
                  <a
                    href="https://book.nightsbridge.com/14037"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    <span>Book Now</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default About;
