import React from "react";
import "../../assets/styles/components/footer.css";
import logo from "../../assets/images/branding/k-logo-nt.png";

function Footer() {
  return (
    <footer>
      <div className="container text-center">
        <div className="row">
          {/* Contact Information Section */}
          <div className="col">
            {/* Phone and WhatsApp Section */}
            <div className="icon d-flex justify-content-center align-items-center mb-3">

              <div className="text me-4">
                <div className="i me-2">
                  <i className="bi bi-telephone"></i>
                </div>
                <h4>Phone</h4>
                <p>+27-21-552-4390</p>
              </div>
              <a
                href="https://wa.me/27835711688"
                target="_blank"
                rel="noreferrer"
                className="d-flex align-items-center text-decoration-none"
              >
                <div className="text">
                  <div className="i me-2">
                    <i className="bi bi-whatsapp"></i>
                  </div>
                  <h4>WhatsApp</h4>
                  <p>+27-83-571-1688</p> {/* Add your WhatsApp number here */}
                </div>
              </a>
            </div>

            {/* Email Section */}
            <div className="icon d-flex justify-content-center align-items-center mb-3">

              <div className="text">
                <div className="i me-2">
                  <i className="bi bi-envelope-at"></i>
                </div>
                <h4>Email</h4>
                <p>info@kwantuguesthouses.capetown</p>
              </div>
            </div>

            {/* Location Section */}
            <div className="icon d-flex justify-content-center align-items-center">

              <div className="text">
                <div className="i me-2">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <h4>Location</h4>
                <p>67 Milnerton Drive, Milnerton Ridge, Cape Town</p>
              </div>
            </div>
          </div>

          {/* Logo and Social Media Section */}
          <div className="col">
            <div className="logo pb-3">
              <a href="#home">
                <img src={logo} alt="Kwantu Guesthouses Logo" />
              </a>
            </div>
            <div className="social">
              <h4>Follow Kwantu</h4>
              <a
                href="https://www.facebook.com/profile.php?id=61550234398860"
                target="_blank"
                rel="noreferrer"
                className="me-3"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/kwantu_guesthouses/?fbclid=IwAR2mbjsv-KYs1lO-ADbM2SehE2Hyg0UQNnyKo_AG9C0kKE-9rKaPMAGj3M8"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </div>
          </div>

          {/* Description Section */}
          <div className="col">
            <div className="q-links pt-5">
              <p>
                Experience the ultimate in comfort and convenience at Kwantu Cape Town.
                Our guesthouses offer exceptional accommodation for business travelers
                and leisure guests alike. With easy access to the Central Business District,
                stunning beaches, renowned wine farms, Table Mountain, and the V&A Waterfront,
                Kwantu Cape Town is your perfect home away from home.
              </p>
            </div>
          </div>
        </div>

        {/* Credits Section */}
        <div className="row credit-con mt-4">
          <p className="credit">
            All Rights Reserved ||
            <span>
              <a href="#home" className="px-1 sitename">Kwantu Guesthouses</a>
            </span>
            <br />
            Website by
            <a
              href="https://walterhouse.co.za/"
              target="_blank"
              className="px-1 sitename"
              rel="noreferrer"
            >
              Walter House
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
