import React, { useState, useEffect } from "react";
import "../../assets/styles/components/galleryComp.css";

const GalleryComp = ({ images }) => {
  
  return (
    <div className="container">
      <div className="row">
        {Object.keys(images).map((key, index) => (
          <div
            key={index}
            className={`col-md-${index % 3 === 0 ? "12" : "6"} mb-3`}
          >
            <img
              src={images[key]}
              alt={`Image ${index + 1}`}
              className="img-fluid w-100 rounded"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryComp;
