import React from "react";
import ImageGrid from "./components/ImageGrid";

const Attractions = () => {
  return (
    <section className="container mt-5">
      <div className="heading text-center">
        <h5>So Much To Do</h5>
        <h1>Local Attractions</h1>
        <p>
          Kwantu Cape Town is surrounded by a wealth of local amenities and
          activities to keep you entertained. Discover nearby shopping centers,
          restaurants, and cafes offering a variety of cuisines. Our guesthouses
          are also close to cultural landmarks, museums, and entertainment
          venues, ensuring there's always something exciting to do.
        </p>
      </div>

      {/* Image Grid for Local Attractions */}
      <ImageGrid />

      <div className="text-center attractions mt-4">
        <div className="card">
          <div className="card-header">
            <h5>Kwantu's Top 21</h5>
            <p>
              Whether you're looking to book a sightseeing adventure, spa treatment, game drive, or township tour, speak to our friendly and knowledgeable team who will be more than happy to assist in planning and booking your adventures.
            </p>
          </div>
          <div className="card-body">
            <h5 className="card-title">Cape Town Attractions</h5>
            <div className="row">
              <div className="col-md-4">
                <ul className="card-text">
                  <li>Kirstenbosch National Botanical Garden</li>
                  <li>V&A Waterfront</li>
                  <li>Aquila Game Reserve</li>
                  <li>Two Oceans Aquarium</li>
                  <li>Zeitz Museum of Contemporary Art Africa</li>
                  <li>Cape of Good Hope</li>
                  <li>Atlantic Spa Treatment</li>
                  
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="card-text">
                <li>Iziko Bo-Kaap Museum</li>
                
                  <li>Lions Head</li>
                  <li>Canal Walk Shopping Centre</li>
                  <li>Table Mountain National Park</li>
                  <li>District Six Museum</li>
                  <li>Atlantis Dunes</li>
                  <li>Oranjezicht City Farm Market</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="card-text">
                  
                  <li>Sunset Cruises</li>
                  <li>Blouberg Beach</li>
                  <li>Muizenberg Beach</li>
                  <li>DHL Stadium</li>
                  <li>Robben Island</li>
                  <li>Iziko South African Museum</li>
                  <li>Franschhoek Wine Estates</li>
                </ul>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-dark mt-3"
              data-bs-toggle="modal"
              data-bs-target="#contactModal"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Attractions;
