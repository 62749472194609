import React from "react";
import "../../assets/styles/pages/review.css";
import google from "../../assets/images/partners/google.png";

function Review() {
  const reviews = [
    {
      stars: 5,
      title: "Great Hospitality",
      text: "The hospitality is great, the food is more than enough, the staff is very friendly. Great place to go and stay.",
      name: "Paxton Morrison",
    },
    {
      stars: 4,
      title: "Comfortable Beds",
      text: "Excellent customer care service, great breakfast, comfortable bed and friendly staff members. Would Reccomend",
      name: "Masoka Mabena",
    },
    {
      stars: 5,
      title: "Delicious Food",
      text: "We had our agent meeting there. The owner was very friendly, down to earth and humble. Friendly staff and delicious food.",
      name: "Beverly Madien",
    },
  ];

  return (
    <section className="customer py-5 bg-light" id="reviews">
      <div className="container">
        <div className="text-center mb-5 heading">
          <h5>What Our Customers Say</h5>
          <h2>Reviews</h2>
        </div>

        <div className="row text-center gy-4">
          {reviews.map((review, index) => (
            <div key={index} className="col-md-4">
              <div className="card shadow-lg border-light hover-shadow">
                <div className="card-header text-white">
                  <div className="rate">
                    {[...Array(review.stars)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill text-warning"></i>
                    ))}
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title mb-3">{`"${review.title}"`}</h5>
                  <p className="card-text">{review.text}</p>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={google} className="w-50 h-50 g-img" alt="Google Logo" />
                    <h5 className="ms-3">{review.name}</h5>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="col">
            <div className="card shadow-lg border-light hover-shadow">
              <div className="card-header text-center">
                <h5 className="mb-0">Kwantu Guesthouses</h5>
              </div>
              <div className="card-body">
                
                  <div className="d-flex flex-row justify-content-evenly ">
                    <div className="mb-3 col-item">
                      <i className="bi bi-people fs-2"></i>
                      <h6 className="d-inline ms-2">20+ Years in Business</h6>
                    </div>
                    <div className="mb-3 col-item">
                      <i className="bi bi-star-fill fs-2"></i>
                      <h6 className="d-inline ms-2">4+ Google Rating</h6>
                    </div>
                    <div className="mb-3 col-item">
                      <i className="bi bi-chat-heart fs-2"></i>
                      <h6 className="d-inline ms-2">200+ Reviews</h6>
                    </div>
                    <a href="https://book.nightsbridge.com/14037" target="_blank" rel="noopener noreferrer">
                    <button type="button" className="btn btn-dark btn-lg mt-3">
                      <span>Book Now</span>
                    </button>
                  </a>
                  </div>
                  
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
