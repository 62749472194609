import React from "react";

function Contact() {
  return (
    <section className="contact-con bg-light " id="contact">
      <div className="container text-center">
        {/* <!-- Button trigger modal -->
        <!-- Modal --> */}
        <div
          className="modal fade"
          id="contactModal"
          tabIndex="-1"
          aria-labelledby="contactModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <img
                  src="./images/branding/k-logo-nt.png"
                  className="img-fluid"
                  alt=""
                />
                <h2 className="modal-title fs-5" id="contactModalLabel">
                  Kwantu Guesthouses
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="contact">
                  <form
                    className="cf"
                    action="https://formsubmit.co/info@kwantuguesthouses.capetown"
                    method="POST"
                  >
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        name="name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        name="email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="message"
                        placeholder="Hi there!"
                        name="message"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="input-submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
